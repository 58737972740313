@use "@angular/material" as mat;
@import "./rina-colors.scss";

$dark-theme-primary: mat.define-palette(
  $rina-grayweb,
  50,
  100,
  500,
  900
); // most widely used across all screen and components
$dark-theme-accent: mat.define-palette(
  $rina-blue,
  50,
  100,
  500,
  900
); // floating action color and interactive element
$dark-theme-foreground: mat.define-palette(
  $rina-lightblue,
  50,
  100,
  500,
  900
); // for text and icons
$dark-theme-warn: mat.define-palette($rina-warn, 50, 100, 500, 900);
$dark-theme: mat.define-dark-theme(
  $dark-theme-primary,
  $dark-theme-accent,
  $dark-theme-foreground
);

@function color-get($map, $key, $opacity) {
  // input palette, color number, opacity  --> output rgba
  $value: map-get($map, $key);
  @return rgba($value, $opacity);
}

.light-theme {
  .drawer {
    background-image: linear-gradient(
      color-get($dark-theme-accent, 500, 1),
      color-get($dark-theme-foreground, 500, 1)
    );
  }

  .sidenav {
    background-image: linear-gradient(
      color-get($dark-theme-accent, 500, 1),
      color-get($dark-theme-foreground, 500, 1)
    );
  }

  %page-toolbar {
    height: 55px;
    color: white;
    background: mat.get-color-from-palette($rina-lightblue, 500);

    mat-nav-list mat-list-item,
    mat-list mat-list-item {
      color: white;

      &.active {
        color: mat.get-color-from-palette($rina-darkblue, 500);
      }
    }
  }

  %rina-text {
    color: mat.get-color-from-palette($rina-darkblue, 500, 0.7);

    &:hover {
      color: mat.get-color-from-palette($rina-darkblue, 500, 1);
    }
  }

  .mat-tooltip {
    background: mat.get-color-from-palette($rina-gray, 500, 0.9);
  }

  .mat-drawer-container {
    color: mat.get-color-from-palette($rina-darkblue, 500, 0.7);
    background-color: mat.get-color-from-palette($rina-grayweb, 500);
  }

  cube-sidemenu {
    $dark-theme-primary: mat.define-palette($rina-lightblue, 500, 100, 800);
    $dark-theme-accent: mat.define-palette($rina-darkblue, 500, 100, 800);
    $dark-theme-warn: mat.define-palette($rina-warn);
    $dark-theme: mat.define-dark-theme(
      $dark-theme-primary,
      $dark-theme-accent,
      $dark-theme-warn
    );
    @include mat.all-component-themes($dark-theme);
    @include mat.list-theme($dark-theme);
  }

  cube-toolbar {
    .mat-toolbar {
      background: mat.get-color-from-palette($rina-grayweb, 500);
      color: mat.get-color-from-palette($rina-darkblue, 500);
    }
  }

  /*cube-map-framework {
    .mat-toolbar.page-toolbar {
      @extend %page-toolbar;
    }
  }*/
  .mat-toolbar.page-toolbar {
    @extend %page-toolbar;
  }

  mat-form-field {
    &.mat-mdc-form-field-appearance-outline {
      .mat-mdc-form-field-outline {
        // color: mat-color($rina-blue, 500);
      }

      .mat-mdc-form-field-outline-thick {
        color: mat.get-color-from-palette($rina-lightblue, 500);
      }
    }
  }

  cube-wtg-details {
    mat-card-header {
      background-color: mat.get-color-from-palette($rina-blue, 500);

      .mat-mdc-card-title {
        color: rgba(255, 255, 255, 1);
      }

      .mat-mdc-card-subtitle {
        color: rgba(255, 255, 255, 0.85);
      }

      .mat-mdc-icon-button {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  cube-details-tab {
    .details-container {
      background: linear-gradient(
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        url("/../assets/map/wind/blades_landscape.png") repeat-x fixed 10px
          bottom;
    }
  }

  .standard-dropdown {
    .standard-dropdown-search,
    .search-icon {
      color: #000000;
    }
  }
}
