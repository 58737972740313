@mixin cube-toolbar-theme($theme) {

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  cube-toolbar{

    /*.mat-toolbar{
      color: mat-color($warn);
    }*/

  }

}

