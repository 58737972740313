/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.page-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: calc(100% - 64px);
  padding: 30px;
}

.table-container {
  @include mat.elevation(1);
  border-radius: 4px;
  max-width: 100%;
  position: relative;

  > .table {
    overflow: auto;

    table.mat-mdc-table {
      width: 100%;

      tr.mat-mdc-row {
        height: 55px;
      }

      th.mat-mdc-header-cell {
        border-color: #4f899b;
        border-bottom-width: 1px;
        color: #4f899b;
        font-size: 17px;
        font-weight: 400;
      }

      td.no-data {
        text-align: center;
      }

      th.mat-mdc-header-cell,
      td.mat-cell,
      td.mat-mdc-footer-cell {
        padding: 10px;

        &:first-of-type {
          padding-left: 25px;
        }

        &:last-of-type {
          padding-right: 25px;
        }

        &.center {
          text-align: center;

          .mat-sort-header-container {
            justify-content: center;

            .mat-sort-header-content {
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}

.dark-theme {
  .dialogTitle{
    color: white;
  }
}
