/*http://mcg.mbitson.com/#!?rinablue=%230076a5&rinagray=%2397999b&rinadark=%2313294b&rinalight=%233eb1c8&themename=blue*/

$rina-darkblue: (
    50 : #e3e5e9,
    100 : #b8bfc9,
    200 : #8994a5,
    300 : #5a6981,
    400 : #364966,
    500 : #13294b, // rina darkblue
    600 : #112444,
    700 : #0e1f3b,
    800 : #0b1933,
    900 : #060f23,
    A100 : #6085ff,
    A200 : #2d5eff,
    A400 : #003af9,
    A700 : #0034e0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);


$rina-lightblue: (
    50 : #e8f6f8,
    100 : #c5e8ef,
    200 : #9fd8e4,
    300 : #78c8d9,
    400 : #5bbdd0,
    500 : #3eb1c8, // rina lightblue
    600 : #38aac2,
    700 : #30a1bb,
    800 : #2898b4,
    900 : #1b88a7,
    A100 : #def7ff,
    A200 : #abebff,
    A400 : #78deff,
    A700 : #5ed8ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$rina-blue: (
    50 : #e0eff4,
    100 : #b3d6e4,
    200 : #80bbd2,
    300 : #4d9fc0,
    400 : #268bb3,
    500 : #0076a5, // rina blue
    600 : #006e9d,
    700 : #006393,
    800 : #00598a,
    900 : #004679,
    A100 : #a7d4ff,
    A200 : #74bbff,
    A400 : #41a2ff,
    A700 : #2795ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$rina-darkgray: (
    50 : #eaeaea,
    100 : #c9caca,
    200 : #a6a7a7,
    300 : #828383,
    400 : #676969,
    500 : #4c4e4e, // rina darkgray
    600 : #454747,
    700 : #3c3d3d,
    800 : #333535,
    900 : #242525,
    A100 : #7ff3f3,
    A200 : #51eeee,
    A400 : #0dffff,
    A700 : #00f2f2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$rina-lightgray: (
    50 : #fafafa,
    100 : #f4f3f3,
    200 : #ecebeb,
    300 : #e4e2e2,
    400 : #dfdcdc,
    500 : #d9d6d6, // rina lightgray
    600 : #d5d1d1,
    700 : #cfcccc,
    800 : #cac6c6,
    900 : #c0bcbc,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$rina-grayweb: (
    50 : #fefefe,
    100 : #fdfdfd,
    200 : #fcfcfc,
    300 : #fafafa,
    400 : #f9f9f9,
    500 : #f8f8f8, // rina grayweb
    600 : #f7f7f7,
    700 : #f6f6f6,
    800 : #f5f5f5,
    900 : #f3f3f3,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$rina-gray: (
    50 : #f3f3f3,
    100 : #e0e0e1,
    200 : #cbcccd,
    300 : #b6b8b9,
    400 : #a7a8aa,
    500 : #97999b, // rina gray
    600 : #8f9193,
    700 : #848689,
    800 : #7a7c7f,
    900 : #696b6d,
    A100 : #eaf2fd,
    A200 : #bbd4f9,
    A400 : #82b4ff,
    A700 : #68a5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$rina-accent: (
    50 : #fff6e0,
    100 : #ffe9b3,
    200 : #ffdb80,
    300 : #ffcc4d,
    400 : #ffc126,
    500 : #ffb600,
    600 : #ffaf00,
    700 : #ffa600,
    800 : #ff9e00,
    900 : #ff8e00,
    A100 : #ffffff,
    A200 : #fff9f2,
    A400 : #ffe0bf,
    A700 : #ffd3a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$rina-warn: (
    50 : #ffe6e0,
    100 : #ffc0b3,
    200 : #ff9680,
    300 : #ff6b4d,
    400 : #ff4c26,
    500 : #ff2c00,
    600 : #ff2700,
    700 : #ff2100,
    800 : #ff1b00,
    900 : #ff1000,
    A100 : #ffffff,
    A200 : #fff3f2,
    A400 : #ffc1bf,
    A700 : #ffa8a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


