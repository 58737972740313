html {
  --mat-badge-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 300 14px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 300 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 300 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 300 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 300 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 300 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 300 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 300 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 300 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 300 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 300 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 300;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 300;
  --mat-standard-button-toggle-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 300;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 300;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 300;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 300;
}

html {
  --mat-expansion-header-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 300;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 300;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 300;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 300;
}

html {
  --mat-toolbar-title-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 300;
}

html {
  --mat-tree-node-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 300;
}

html {
  --mat-option-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 300;
}

html {
  --mat-optgroup-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 300;
}

html {
  --mat-card-title-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 300;
  --mat-card-subtitle-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 300;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 300;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 300;
  --mdc-outlined-text-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 300;
  --mat-form-field-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 300;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 300;
}

html {
  --mat-select-trigger-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 300;
}

html {
  --mdc-dialog-subhead-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 300;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 300;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 300;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 300;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 300;
}

html {
  --mdc-slider-label-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 300;
}

html {
  --mat-menu-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 300;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 300;
  --mdc-list-list-item-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 300;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 300;
}

.mdc-list-group__subheader {
  font: 300 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 300;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 300;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 300;
}

html {
  --mdc-text-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 300;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 300;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 300;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 300;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 300;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 300;
}

html {
  --mat-table-header-headline-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 300;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 300;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 300;
  --mat-table-footer-supporting-text-tracking: normal;
}